<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="production-barrels"
              :show-labels="true"
              :loaded="filteringPanel.loaded"
              :load-dictionaries="loadDictionaries"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              @search="getData"
              @change="filterData"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row v-show="dataTable.visible">
          <b-col>
            <b-tabs content-class="mt-3" justified>
              <b-tab ref="overview-tab" title="Barrels" active>
                <barrels-table ref="barrels" @loaded="onBarrelsLoad" />
              </b-tab>
              <b-tab ref="by-product-tab" title="By Product">
                <barrels-by-product-table ref="byproduct" />
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import BarrelsTable from "@/views/Production/Barrels/BarrelsTable";
import BarrelsByProductTable from "@/views/Production/Barrels/BarrelsByProductTable";

export default {
  name: "Accounts",
  components: {
    FilteringPanel,
    BarrelsTable,
    BarrelsByProductTable
  },
  data: function() {
    return {
      filteringPanel: {
        loaded: false,
        selected: {},
        districts: [],
        filters: [
          {
            type: "select",
            title: "Barrel status",
            name: "barrel_status",
            trackby: "id",
            label: "label",
            options: [
              {
                id: "1",
                label: "Full"
              },
              {
                id: "2",
                label: "Top off"
              },
              {
                id: "3",
                label: "Reserved"
              }
            ],
            selected: {},
            ///
            dataType: "string",
            tooltip: "Barrel status",
            multiple: true
          },

          {
            type: "select",
            title: "Bottling status",
            name: "barrel_bottling_status",
            trackby: "id",
            label: "label",
            options: [
              {
                id: "0",
                label: "Not bottled"
              },
              {
                id: "1",
                label: "Bottled"
              }
            ],
            selected: {},
            ///
            dataType: "string",
            tooltip: "Bottling status",
            multiple: true
          },

          {
            type: "select",
            title: "Amount remaining",
            name: "barrel_amount_remaining",
            trackby: "id",
            label: "label",
            options: [
              {
                id: "0",
                label: "0",
                min: 0,
                max: 0
              },
              {
                id: "1",
                label: " 0.1 - 100",
                min: 0.1,
                max: 100
              },
              {
                id: "2",
                label: "100.01 - 200",
                min: 100.01,
                max: 200
              },
              {
                id: "3",
                label: "200.01 - 300",
                min: 200.01,
                max: 300
              }
            ],
            selected: {},
            ///
            dataType: "number",
            tooltip: "Amount remaining",
            multiple: true
          },

          {
            type: "daterange",
            defaultRange: "Empty",
            title: "Reservation Date",
            name: "barrel_reservation_period",
            dataType: "datetime",
            tooltip: "Reservation period",
            allowEmpty: true
          },

          {
            type: "daterange",
            defaultRange: "Empty",
            title: "Barrel Date",
            name: "barrel_date",
            dataType: "datetime",
            tooltip: "Barrel date",
            allowEmpty: true
          },

          {
            type: "daterange",
            defaultRange: "Empty",
            title: "Modified Date",
            name: "barrel_modified_date",
            dataType: "datetime",
            tooltip: "Modified date",
            allowEmpty: true
          },

          {
            type: "daterange",
            defaultRange: "Empty",
            title: "Bottled Date",
            name: "barrel_bottled_date",
            dataType: "datetime",
            tooltip: "Bottled date",
            allowEmpty: true
          },
          {
            type: "input",
            title: "Barrel #",
            name: "barrel_number",
            dataType: "string",
            tooltip: "Barrel number to search"
          },

          {
            type: "daterange",
            defaultRange: "Empty",
            title: "Transfer Date",
            name: "barrel_transfer_date",
            dataType: "datetime",
            tooltip: "Transfer date",
            allowEmpty: true
          },
          {
            type: "daterange",
            defaultRange: "Empty",
            title: "Prev Transfer Date",
            name: "barrel_prev_transfer_date",
            dataType: "datetime",
            tooltip: "Prev Transfer date",
            allowEmpty: true
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async loadDictionaries() {},

    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      //if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) this.getData();
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },
    onBarrelsLoad() {},
    filterData(e) {
      this.filteringPanel.selected = e;
    },
    getData() {
      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        barrel_status: f.barrel_status ? f.barrel_status.map(i => i.label) : [],
        barrel_bottling_status: f.barrel_bottling_status
          ? f.barrel_bottling_status.map(i => i.label)
          : [],
        barrel_reservation_period: f.barrel_reservation_period,
        barrel_date: f.barrel_date,
        barrel_bottled_date: f.barrel_bottled_date,
        barrel_modified_date: f.barrel_modified_date,
        barrel_transfer_date: f.barrel_transfer_date,
        barrel_prev_transfer_date: f.barrel_prev_transfer_date,
        barrel_number: f.barrel_number
      };

      if (f.barrel_amount_remaining && f.barrel_amount_remaining.length > 0) {
        let minAmountRemaining = f.barrel_amount_remaining.reduce(
          (min, p) => (p.min < min ? p.min : min),
          f.barrel_amount_remaining[0].min
        );

        let maxAmountRemaining = f.barrel_amount_remaining.reduce(
          (max, p) => (p.max > max ? p.max : max),
          f.barrel_amount_remaining[0].max
        );

        payload.barrel_amount_remaining = {
          minAmountRemaining: minAmountRemaining,
          maxAmountRemaining: maxAmountRemaining
        };
      }

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$refs["barrels"].getData(payload);
      this.$refs["byproduct"].getData(payload);
    }
  },
  watch: {}
};
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}
</style>
