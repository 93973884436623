<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @filter="onFilter"
      @row-select="onRowSelect"
    >
      <div slot="afterFilter">
        <b-row>
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Bulk update"
                :disabled="has2SelectedRows ? false : true"
                @click="moveBarrels()"
              >
                <font-awesome-icon icon="external-link-alt" /> Bulk move
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <b-button
            size="sm"
            variant="primary"
            title="View barrel"
            @click="viewBarrel(props.row)"
          >
            <font-awesome-icon icon="eye" />
          </b-button>

          <b-button
            size="sm"
            variant="info"
            title="Edit barrel"
            @click="editBarrel(props.row)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </b-button>

          <b-button
            v-if="
              +props.row['Bottlings Count'] == 0 && !props.row['Sample Request']
            "
            size="sm"
            variant="danger"
            title="Delete barrel"
            @click="deleteBarrel(props.row)"
          >
            <font-awesome-icon icon="trash" />
          </b-button>
        </div>
      </div>
    </table-custom>

    <b-modal
      ref="move-barrels-modal"
      centered
      scrollable
      size="md"
      title="Bulk barrels move"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <b-container fluid class="ma-0 pa-0">
        <b-row>
          <b-col lg="4" md="4" sm="12">
            Selected barrels:
          </b-col>

          <b-col lg="8" md="8" sm="12">
            <p>{{ moveBarrelsModal.data.selectedBarrels.join() }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <inline-select
              :id="moveBarrelsModal.controls.warehouse.id"
              :value="moveBarrelsModal.data.warehouse"
              :label="moveBarrelsModal.controls.warehouse.label"
              :readonly="moveBarrelsModal.controls.warehouse.readonly"
              :options="moveBarrelsModal.controls.warehouse.options"
              :allow-empty="false"
              :multiple="false"
              :mode="$constants.FORM_MODE.EDIT"
              @changed="updateDestinationWarehouse"
              :required="moveBarrelsModal.controls.warehouse.required"
            />
          </b-col>
        </b-row>
      </b-container>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          :disabled="!moveBarrelsModal.data.warehouse.id"
          variant="outline-dark"
          class="m-1"
          @click="processBarrelsMove()"
        >
          <b-spinner v-if="moveBarrelsModal.isLoading" small type="grow" />
          <font-awesome-icon
            v-if="!moveBarrelsModal.isLoading"
            icon="external-link-alt"
          />
          Move
        </b-button>
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="closeMoveBarrelsModal()"
        >
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TableCustom from '@/components/TableCustom'
import InlineSelect from '@/components/InlineSelectMultiple'
import { mapState } from 'vuex'

export default {
  name: 'BarrelsTable',
  components: {
    InlineSelect,
    TableCustom
  },
  data: function () {
    return {
      isReviewer: false,
      rawData: {},
      appliedFilters: [],
      selectedRows: [],
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          uniqueKey: 'ID',
          showChildRowToggler: false,
          filterByColumn: true,
          columns: [
            'Barrel #',
            'Type',
            'Date Barreled',
            'Age',
            'Amount',
            'Amount Remaining',
            'Previous Barrel #',
            'Size',
            'Product',
            'Alc %',
            'Notes',
            'Label',
            'Status',

            'Reservation Date',
            'Bottling Status',
            'Bottled Date',
            'AF Count',
            'Warehouse',
            'Transfer Date',
            'Sample Request',
            'Prev Warehouse',
            'Prev Transfer Date',
            'Transfers Count',
            'Bottlings Count',
            'Modified',
            'Actions'
          ],
          perPage: 50,
          disablePerPageDropdown: false,
          perPageValues: [],
          showCustomActions: true,
          showChildRows: true,
          selectableRows: true
        },

        dataSet: [],
        onRowClick: function () {},
        totalRecords: 0
      },
      moveBarrelsModal: {
        isLoading: false,
        controls: {
          warehouse: {
            id: 'select:warehouse',
            label: 'New warehouse location',
            options: [],
            changed: false,
            readonly: false,
            loading: false,
            required: false
          }
        },
        data: {
          selectedBarrels: [],
          warehouse: { id: '', label: '' }
        }
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    hasSelectedRows () {
      return this.selectedRows.length > 0
    },
    has2SelectedRows () {
      return this.selectedRows.length > 1
    }
  },
  mounted () {
    let self = this

    this.$api.get('dictionaries/warehouses').then(response => {
      self.moveBarrelsModal.controls.warehouse.options = response.map(u => ({
        id: u.id,
        label: u.name
      }))
    })
  },
  methods: {
    moveBarrels () {
      this.$refs['move-barrels-modal'].show()
    },
    closeMoveBarrelsModal () {
      this.$refs['move-barrels-modal'].hide()
    },
    updateDestinationWarehouse (id, value) {
      this.moveBarrelsModal.data.warehouse = value
    },
    async processBarrelsMove () {
      this.moveBarrelsModal.isLoading = true

      //await this.$helpers.delay(5000);
      this.$api.post('barrels/bulk-move', {
        warehouse: this.moveBarrelsModal.data.warehouse,
        barrels: this.moveBarrelsModal.data.selectedBarrels
      })

      this.moveBarrelsModal.isLoading = false

      await this.$form.msgBoxOk('Barrels were moved')

      this.getData(this.appliedFilters)
    },
    onRowSelect (e) {
      this.selectedRows = this.$refs.dataTable.getSelectedRows()

      this.moveBarrelsModal.data.selectedBarrels = this.selectedRows.map(
        i => i['Barrel #']
      )
      this.$emit('row-select', e)
    },

    async getData (payload) {
      let self = this

      console.log('barrelsTable.getData', payload)

      this.appliedFilters = payload

      this.isReviewer = await this.$api.get('sample-requests/reviewer')

      //display spinner  by default
      if (!payload || !payload.silentUpdate) {
        self.dataTable.isLoading = true
        //self.dataTable.dataSet = [];
      }

      this.$api
        .post('barrels', payload)
        .then(response => {
          self.dataTable.isLoading = false

          self.dataTable.dataSet = response

          if (response.length === 0) return

          self.dataTable.dataSet.forEach(r => {
            r.isRemindToBottleLoading = false
            r.isSellBarrelLoading = false
            r.isUnSellBarrelLoading = false
          })

          self.$emit('loaded', self.dataTable.dataSet.length)
        })
        .catch(error => {
          console.log(error)
          self.dataTable.isLoading = false
          self.$form.msgBoxOk('Error occured')
        })
    },
    toggleFiles (id) {
      this.$refs.dataTable.toggleChildRow(id)
    },
    onFilter () {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length
    },

    viewBarrel (row) {
      this.$router.push({
        name: 'Production barrel submission',
        params: {
          action: 'view',
          id: row['Barrel #']
        }
      })
    },
    editBarrel (row) {
      this.$router.push({
        name: 'Production barrel submission',
        params: {
          action: 'edit',
          id: row['Barrel #']
        }
      })
    },
    async deleteBarrel (row) {
      //let barrel = this.dataTable.dataSet.find(item => item.ID === row.ID);

      let confirm = await this.$form.showConfirmation(
        `Barel #${row.ID} will be deleted. Do you want to proceed?`
      )

      if (!confirm) return

      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        item => item.ID !== row.ID
      )

      let self = this

      this.$api
        .delete(`barrels/${row.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          self.getData()
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(response.message)
        })
    }
  }
}
</script>

<style scoped>
::v-deep .modal-dialog-scrollable .modal-content {
  overflow: visible !important;
}

::v-deep .modal-dialog-scrollable .modal-body {
  overflow: visible !important;
}
</style>
