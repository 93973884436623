var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.$route.meta.module)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "filter": _vm.onFilter,
      "row-select": _vm.onRowSelect
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "primary",
            "title": "View barrel"
          },
          on: {
            "click": function click($event) {
              return _vm.viewBarrel(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "info",
            "title": "Edit barrel"
          },
          on: {
            "click": function click($event) {
              return _vm.editBarrel(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), +props.row['Bottlings Count'] == 0 && !props.row['Sample Request'] ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger",
            "title": "Delete barrel"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteBarrel(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1) : _vm._e()], 1)]);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [_c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Bulk update",
      "disabled": _vm.has2SelectedRows ? false : true
    },
    on: {
      "click": function click($event) {
        return _vm.moveBarrels();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "external-link-alt"
    }
  }), _vm._v(" Bulk move ")], 1)], 1)], 1)], 1)], 1)]), _c('b-modal', {
    ref: "move-barrels-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "md",
      "title": "Bulk barrels move",
      "hide-footer": "",
      "no-close-on-esc": "",
      "no-close-on-backdrop": ""
    }
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_vm._v(" Selected barrels: ")]), _c('b-col', {
    attrs: {
      "lg": "8",
      "md": "8",
      "sm": "12"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.moveBarrelsModal.data.selectedBarrels.join()))])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.moveBarrelsModal.controls.warehouse.id,
      "value": _vm.moveBarrelsModal.data.warehouse,
      "label": _vm.moveBarrelsModal.controls.warehouse.label,
      "readonly": _vm.moveBarrelsModal.controls.warehouse.readonly,
      "options": _vm.moveBarrelsModal.controls.warehouse.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": _vm.moveBarrelsModal.controls.warehouse.required
    },
    on: {
      "changed": _vm.updateDestinationWarehouse
    }
  })], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "disabled": !_vm.moveBarrelsModal.data.warehouse.id,
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.processBarrelsMove();
      }
    }
  }, [_vm.moveBarrelsModal.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.moveBarrelsModal.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "external-link-alt"
    }
  }) : _vm._e(), _vm._v(" Move ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeMoveBarrelsModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }