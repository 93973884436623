var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-3"
  }, [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "production-barrels",
      "show-labels": true,
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "search": _vm.getData,
      "change": _vm.filterData,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dataTable.visible,
      expression: "dataTable.visible"
    }]
  }, [_c('b-col', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    ref: "overview-tab",
    attrs: {
      "title": "Barrels",
      "active": ""
    }
  }, [_c('barrels-table', {
    ref: "barrels",
    on: {
      "loaded": _vm.onBarrelsLoad
    }
  })], 1), _c('b-tab', {
    ref: "by-product-tab",
    attrs: {
      "title": "By Product"
    }
  }, [_c('barrels-by-product-table', {
    ref: "byproduct"
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }