<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
    </table-custom>
  </div>
</template>

<script>
export default {
  name: "BarrelsByProductTable",
  components: {},
  data: function() {
    return {
      isReviewer: false,
      rawData: {},
      appliedFilters: [],

      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: false,
          columns: [
            "Product",
            "Amount Remaining",
            "PG Remaining",
            "Barrel Size",

            "Barrel Type",
            "Barrels Remaining calculated",
            "Barrels actual",
            "Cases",
            "Values"
          ],
          perPage: 50,
          disablePerPageDropdown: false,
          perPageValues: []
        },

        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async getData(payload) {
      let self = this;

      this.appliedFilters = payload;

      self.dataTable.isLoading = true;

      this.$api
        .post("barrels/byproduct", payload)
        .then(response => {
          self.dataTable.isLoading = false;

          self.dataTable.dataSet = response;

          if (response.length === 0) return;

          self.dataTable.dataSet.forEach(r => {
            r.isRemindToBottleLoading = false;
            r.isSellBarrelLoading = false;
            r.isUnSellBarrelLoading = false;
          });

          self.$emit("loaded", self.dataTable.dataSet.length);
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    }
  }
};
</script>

<style scoped></style>
